<template>
  <div class="container">
    <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
      <div v-for="item in list" :key="item.comment_id" class="list-item mb10">
        <div class="list-item-header van-hairline--bottom">
          <div class="list-item-avatar"><van-image :src="item.commnet_avatar" alt="" /></div>
          <div class="list-item-inner">
            <div class="list-item-name">{{ item.commnet_name }}</div>
            <div class="list-item-star">
              <van-rate
                :value="item.commnet_rate | formatNumber"
                :size="14"
                :color="globalData.theme.color1"
                allow-half
                void-icon="star"
                void-color="#eee"
                readonly
              />
            </div>
          </div>
          <div class="list-item-date">{{ item.comment_at }}</div>
        </div>
        <div class="list-item-content"><p>{{ item.commnet_content }}</p></div>
        <div v-if="item.comment_image.length" class="list-item-images">
          <van-image
            v-for="(image,imageIndex) in item.comment_image"
            :key="imageIndex"
            class="list-item-image"
            :src="image"
            @click="onCommentPerview(imageIndex,item.comment_image)"
          />
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { Rate } from 'vant'
import PageMixin from '@/mixins/page'
import { ImagePreview } from 'vant'

export default {
  name: 'WeistoreComment',
  components: {
    [Rate.name]: Rate
  },
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {
    // this.onListLoad()
  },
  methods: {
    onListLoad() {
      this.$api.store_productcomment({ 'per-page': this.pageSize, page: this.pageCurrent, product_id: this.$route.query.product_id }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '您还没有评价信息~'
        this.pageCurrent += 1
      })
    },
    onCommentPerview(index, images) {
      WeixinJSBridge.invoke("imagePreview", {
          "urls":images,
          "current": images[index]
      })
    }
  }
}
</script>

<style lang="less" scoped>

  .list-item{
    background:#fff;
    &-avatar{
      width:40px;
      height:40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right:5px;
    }
    &-name{
      font-size: 14px;
      color:#555;
      line-height: 1;
    }
    &-inner{
      flex:1;
      padding-top:4px;
    }
    &-date{
      font-size: 12px;
      color:#999;
    }
    &-header{
      padding:10px 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-content{
      padding:10px 15px;
      color:#999;
      font-size: 14px;
    }
    &-images {
      padding:10px 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-image {
      width: 80px;
      height: 80px;
      margin-right: 10px;
    }
  }

.no-data {
  font-size: 16px;
  color: #999;
  text-align: center;
  padding-top: 150px;
}
</style>

